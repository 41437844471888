/* The container */

.checkbox-label {
    display: flex;
    font-size: 14px;
    text-align: left;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox {
    fill: #dde2ec;
    display: block;
    transition: all 0.15s ease 0s;

    &.checked {
        fill: #5684f8;
    }
}

.checkbox-label input:active ~ .checkbox {
    fill: #c9ced7;

    &.checked {
        fill: #2e69ff;
    }
}

.checkbox-text {
    display: inline-block;
    margin: 0;
    padding-left: 10px;
    width: inherit !important;
    text-align: left !important;
    vertical-align: middle;
}

.checkicon {
    position: absolute;
    display: block;
    color: white;
    width: inherit !important;
    top: 2px;
    left: 0px;

    &.hidden {
        opacity: 0;
    }
}
