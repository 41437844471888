.timeline-nav-wrapper {
    .timeline-nav-container {
        height: 52px;
        position: relative;
        border: solid 2px rgba(149, 170, 201, 0.5);
        border-radius: 8px;

        .selector {
            height: inherit;
            margin-top: -2px;
            margin-bottom: -2px;
            margin-left: -2px;
            margin-right: -2px;
            background-color: rgba(86, 132, 248, 0.16);
            border: solid 2px #5684f8;
            border-radius: 8px;
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }

        .handle-prev {
            position: absolute;
            cursor: col-resize;
            top: 11px;
            left: -11px;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        .handle-next {
            position: absolute;
            cursor: col-resize;
            top: 11px;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
        }

        .timeline-separator {
            width: 2px;
            height: 100%;
            position: absolute;
            top: 0;
            background-color: rgba(149, 170, 201, 0.3);
        }
    }

    .timeline-label-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        p {
            height: 42px;
            font-size: 14px;
            text-align: center;
            white-space: pre;
        }

        .size-1 {
            width: 71px;
        }

        .size-2 {
            width: 39px;
        }

        .size-3 {
            width: 44px;
        }
    }
}
