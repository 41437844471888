body {
    font-family: 'Ubuntu', sans-serif !important;
    height: 100vh;
    background-color: #f9fbfd;
    color: #12263f;
}

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

.root-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .widget-container {
        width: 100%;
        background-color: white;
        border: 1px solid #edf2f9;
        border-radius: 8px;
        box-shadow: rgba(18, 38, 63, 0.03) 0 12px 24px 0;
    }
}

.ui-button {
    width: 32px;
    height: 32px;
    background-color: #f3f5f9;
    text-align: center;
    color: #2f363d;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    outline: none;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */

    &:hover {
        background-color: #e3e9f3;
    }

    &:active {
        background-color: #d4d8e2;
    }

    &.selected {
        background-color: #5684f8;
        color: white;
    }
}

.circle-button {
    width: 32px;
    height: 32px;
    background-color: #ecf0f6;
    outline: none;
    border-radius: 999px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    color: #2f363d;
    cursor: pointer;
    transition: 0.3s background;

    img {
        padding: 11px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
    }

    &:hover {
        background-color: #e3e9f3;
    }

    &:active {
        background-color: #d4d8e2;
    }
}

.primary-button {
    height: 38px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #5684f8;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    outline: none !important;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */

    &:hover:enabled {
        background-color: #3d67f6;
    }

    &:active:enabled {
        background-color: #3153c4;
    }

    &:disabled {
        opacity: 0.65;
        cursor: default;
    }
}

.secondary-button {
    height: 38px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #ecf0f6;
    text-align: center;
    color: #2f363d;
    font-size: 16px;
    font-weight: 400;
    line-height: 38px;
    outline: none !important;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */

    &:hover:enabled {
        background-color: #e3e9f3;
    }

    &:active:enabled {
        background-color: #d4d8e2;
    }

    &:disabled {
        opacity: 0.65;
    }
}

.red-button {
    height: 38px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #ff7262;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    outline: none !important;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    cursor: pointer;
    transition: 0.3s background;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                       supported by Chrome, Edge, Opera and Firefox */

    &:hover:enabled {
        background-color: #fc5745;
    }

    &:active:enabled {
        background-color: #cf5649;
    }

    &:disabled {
        opacity: 0.65;
        cursor: default;
    }
}

.reversed-icon {
    transform: rotate(180deg);
}

.light-gray-text {
    color: #a2a8b1;
    font-size: 14px;
}

.vr {
    width: 1px;
    margin: 0 1rem;
    color: inherit;
    background-color: rgb(33, 37, 41);
    border: 0;
    opacity: 0.25;
}

.sim-nav-link {
    cursor: pointer;
}

.h1-skeleton {
    height: 42px;
}

.h3-skeleton {
    height: 30px;
}

.h5-skeleton {
    height: 30px;
}

.p-skeleton {
    height: 18px;
}

.skeleton {
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.12);
    cursor: wait;
    -webkit-mask-image: radial-gradient(#fff, #000);
    mask-image: radial-gradient(#fff, #000);

    &:before {
        content: ' ';
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
        -webkit-animation: b-skeleton-animate-wave 1.75s linear infinite;
        animation: b-skeleton-animate-wave 1.75s linear infinite;
    }
}

@-webkit-keyframes b-skeleton-animate-wave {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}
@keyframes b-skeleton-animate-wave {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

// Content header
.content-header-wrapper {
    overflow-x: auto;

    .content-header {
        min-width: 820px;
        justify-content: end;
    }
}

@media (max-width: 992px) {
    .content-header {
        justify-content: start !important;
    }
}

.description-text {
    color: #a2a8b1;
    font-size: 14px;
}

// Bootstrap overrides
.navbar {
    box-shadow: rgb(0 0 0 / 10%) 0 1px 3px, rgb(0 0 0 / 6%) 0 1px 2px;
}

.enabled {
    opacity: 1 !important;
}

.disabled {
    opacity: 0.65 !important;
}

input {
    padding: 7px 14px;
    font-size: 14px !important;
    border: 2px solid #dde2ec;
    border-radius: 8px;
    outline: 0;
    transition: all 0.2s ease 0s;

    &:focus {
        border: 2px solid #5684f8;
    }

    &.error {
        border: 2px solid #ff7262;
    }
}

textarea {
    padding: 7px 14px;
    font-size: 14px !important;
    border: 2px solid #dde2ec;
    border-radius: 8px;
    outline: 0;
    transition: all 0.2s ease 0s;

    &:focus {
        border: 2px solid #5684f8;
    }

    &.error {
        border: 2px solid #ff7262;
    }
}

label {
    text-align: left;
    margin-left: 0.125rem;
    margin-bottom: 0.25rem;
    font-size: 12px;
}

.modal-content {
    border-radius: 8px;
}

// Alert toast overrides
.toast {
    background: white !important;
}

.btn-close {
    &:focus {
        outline: 0 !important;
        box-shadow: none !important;
    }
}
