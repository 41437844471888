.data-query-main-container {
    height: 100%;

    .data-query-devices-options {
        display: flex;
        flex-direction: column;
    }

    .data-query-date-options {
        display: flex;
        flex-direction: row;

        .options-export-button {
            margin-top: 38px;
        }
    }
}
.query-result-container {
    display: flex;
    flex-direction: column;

    .options-control {
        display: flex;
        flex-direction: row;

        .chevron-right {
            margin-left: 2px;
        }

        .data-count-from-to {
            width: 110px;
            text-align: center;
        }
    }

    .query-result-table {
        position: relative;
        width: 0;
        overflow-x: auto;

        table {
            width: 100%;

            thead tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                th {
                    text-align: center;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
                    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
                }

                .viewer-col-head {
                    cursor: pointer;

                    .col-order-by-symbol {
                        display: inline;
                        // color: #2e69ff;
                        color: #5684f8;
                    }
                }
            }

            tbody {
                td {
                    text-align: center;
                }

                &.data-loading {
                    opacity: 0.6;
                    filter: blur(3px);
                }
            }
        }

        .table-status-absolute-container {
            position: absolute;
            top: 30px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
