.sidebar-container {
    min-width: 300px;
    min-height: 100vh;
    overflow: auto;
    z-index: auto !important;
    outline: 0;
    z-index: 1050;
    background-color: white;
    color: #2f363d;
    border-right: 1px solid #edf2f9;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px, rgba(0, 0, 0, 0.06) 0 1px 2px;
    // box-shadow: rgba(18, 38, 63, 0.03) 0 12px 24px;

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        // border-color: #EDF2F9;
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title-text {
            font-weight: 500;
        }

        .user-text {
            font-size: 12px;
            font-weight: 400;
            opacity: 0.4;
        }
    }

    .category-text {
        .title-text {
            font-weight: 400;
        }
    }

    .category-item {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &:hover {
            background-color: #f8fafd;
        }

        &:active {
            background-color: #ebf0f1;
        }

        &.selected {
            background-color: #f8fafd;
            color: #2e69ff;
            font-weight: 500;
        }
    }

    .data-query-icon {
        // Icon specific margins
        margin-left: 3px;
        margin-right: 1px;
    }

    .logout-text {
        width: max-content;
    }
}
