.daily-count-wrapper {
    border-right-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-right-style: solid;

    .min-inline {
        min-inline-size: 220px;
    }

    .min-inline-cars {
        min-inline-size: 280px;
    }
}
