.tab-list-container {
    display: flex;
    flex-direction: column;

    .tabs {
        position: relative;
        border-bottom: 1px solid rgb(47, 54, 61, 0.25);

        .active-border {
            position: absolute;
            width: 0;
            left: 0;
            bottom: -1px;
            border-bottom: 2px solid #5684f8;

            transition: all .3s;
        }

        .tab-list {
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 5px 20px;
                float: left;
                color: #919EB4;

                transition: all .3s;

                &:hover {
                    color: #3d67f6;
                    cursor: pointer;
                }

                &.active {
                    color: #5684f8;
                }
            }
        }
    }

    .tablist-content {
        height: 100% !important;
        padding-top: 30px;
    }
}