.select-container {
    font-size: 16px;
    line-height: 1.8;
    cursor: pointer;

    &.has-left-icon > .dropdown-checkbox__control > .dropdown-checkbox__value-container {
        padding-left: 34px !important;
    }
}

.dropdown-checkbox__control {
    padding: 3px 14px 2px;
    font-size: 16px;
    line-height: 1.8;
    border-color: #dde2ec;
    border-width: 2px !important;
    border-style: solid !important;
    border-radius: 8px !important;
    box-shadow: 0 0 0 white !important;
    width: 100%;
    //height: 44px;
    color: #12263f;
    cursor: pointer !important;
    transition: all 0.2s ease 0s;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #919eb4;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #919eb4;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: #919eb4;
    }

    &:not(.error){
        border-color: #dde2ec;
    }
}

.dropdown-checkbox__control--is-focused {
    outline: 0;
    border-color: #5684f8 !important;
    border-width: 2px !important;
}

.dropdown-checkbox__option {
    font-size: 16px;
}

.dropdown-checkbox__option:not(.dropdown-checkbox__option--is-disabled) {
    cursor: pointer !important;
    transition: all 0.2s ease 0s;

    &:active {
        background-color: #ebf0f1 !important;
    }
}

.dropdown-checkbox__option:not(.dropdown-checkbox__option--is-selected):not(.dropdown-checkbox__option--is-disabled) {
    &:hover {
        background-color: #f8fafd !important;
    }

    &:active {
        background-color: #ebf0f1 !important;
    }
}

.dropdown-checkbox__option--is-focused {
    background-color: white !important;
}

.dropdown-checkbox__option--is-selected {
    background-color: white !important;
    color: #12263f !important;
}

.dropdown-checkbox-option {
    display: flex;
    flex-direction: row;
    // align-items: center;

    .dropdown-checkbox-label {
        cursor: pointer;
    }
}

.dropdown-checkbox__multi-value {
    background-color: #ecf0f6 !important;
}
