.chart2-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .time-range-selection {
        display: flex;
        flex-direction: row;
    }

    .type-selection {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 16px;
    }
}
.chart2-container {
    table-layout: fixed;
    width: 100%;

    .td-row-icon {
        width: 54px;
    }

    .td-segment {
        padding-left: 0;
        padding-right: 0;
        border: dashed 2px rgba(149, 170, 201, 0.5);

        .segment {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            overflow: overlay;
            border-radius: 8px;
            border-style: solid;
            border-width: 2px;
            font-weight: 500;
            margin-left: -2px;
            margin-right: -2px;
        }
    }

    // Needs to be 2nd, 1st child is icon
    .td-segment:nth-child(2) {
        border-left: none
    }

    .td-segment:last-child {
        border-right: none;
    }

    .td-segment:first-child.segment {
        margin-left: 0;
        margin-right: -2px;
    }

    .td-segment:last-child.segment {
        margin-left: -2px;
        margin-right: 0;
    }

    .td-time {
        width: 100%;

        .time-text {
            white-space: pre;

            &.scale-1 {
                margin-left: -40px;
            }

            &.scale-2 {
                margin-left: -22px;
            }

            &.scale-3 {
                margin-left: -28px;
            }
        }
    }
}

.type-select-button {
    width: auto !important;
    padding: 0 8px;
}