.switch {
    display: inline-block;
    position: relative;
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 24px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 20px;
        opacity: 0;
        z-index: 0;
    }

    // Unchecked
    &__label {
        display: block;
        padding: 0 0 0 50px;
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        margin: 0;

        &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 36px;
            height: 14px;
            background-color: rgba(0, 0, 0, 0.24);
            border-radius: 14px;
            z-index: 0;
            transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 14px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);
            z-index: 0;
            transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            transition-property: left, background-color;
        }
    }

    // Checked
    &__input:checked + &__label {
        &:before {
            background-color: #5684f8;
            opacity: 0.5;
        }

        &:after {
            left: 16px;
            background-color: #5684f8;
        }
    }
}

.switch-text {
    text-align: left;
    font-size: 14px;
    display: inline-block;
    margin: 0;
    padding-left: 10px;
}
