.device-direction-block {
    height: 37px;
}

.device-subtitle {
    border-right-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-right-style: solid;
}

.inline-code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px;
    border-radius: 3px;
    color: #333 !important;
}
